import { ReactNode, useState } from "react";
import styles from "./newsletter.module.css";
import * as Typography from "../../Element/Typography";
import Button from "../../Element/Button";
import { Disclaimer } from "../../Element/Typography";
import cx from "classnames";
import { AlertIcon } from "../../Theme/assets/icons/commonIcons";
import { newsletterConsentText, newsletterTermsText } from "./utils";

const { SubHeading2, SectionTitle } = Typography.Heading;
const Label = Typography.Label;

export type NewsletterProps = {
  emailPlaceHolder: {
    label?: ReactNode;
    placeholder?: string;
  };
  backgroundImage: string;
  newsletterTitle?: ReactNode;
  newsletterBody: ReactNode;
  newsletterCtaText?: ReactNode;
  newsletterLang?: string;
  afterTextInput?: ReactNode;
  fieldValue?: string;
  value?: string;
  onSubmit: (
    email: string,
    isChecked: boolean,
  ) => Promise<{
    ok: boolean;
    message: string;
  }>;
  variant?: "standalone";
};

export default function Newsletter({
  emailPlaceHolder,
  newsletterTitle,
  newsletterBody,
  newsletterCtaText,
  newsletterLang,
  backgroundImage,
  afterTextInput,
  onSubmit,
  variant,
}: NewsletterProps) {
  const [fieldValue, setFieldValue] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const [error, setError] = useState("");
  const [serverMessage, setServerMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className={cx(styles.newsletterContainer, variant && styles[variant])}>
      {/* Background image is positioned absolute */}
      {backgroundImage && (
        <img
          src={backgroundImage}
          alt=""
          className={styles.newsletterImage}
          loading={
            import.meta.env.VITE_SKIP_LAZY || variant === "standalone"
              ? undefined
              : "lazy"
          }
        />
      )}

      <div className={styles.contentContainer}>
        {newsletterTitle && (
          <div className={styles.newsletterTitle}>
            {variant === "standalone" ? (
              <SectionTitle>{newsletterTitle}</SectionTitle>
            ) : (
              <SubHeading2>{newsletterTitle}</SubHeading2>
            )}
          </div>
        )}
        {newsletterBody && (
          <div className={styles.newsletterBody}>
            <p>{newsletterBody}</p>
          </div>
        )}
        <div className={styles.formContainer}>
          {emailPlaceHolder.label && (
            <div>
              <label>
                <Label>{emailPlaceHolder.label}</Label>
              </label>
            </div>
          )}
          <div className={styles.inputContainer}>
            <form
              noValidate
              onSubmit={async (e) => {
                e.preventDefault();
                setServerMessage("");
                if (!validateEmail(fieldValue)) {
                  setIsDirty(true);
                  setError("Please enter a valid email");
                  return; // Don't call onSubmit if the email is not valid
                }
                setIsSubmitting(true); // Set form to submitting state
                try {
                  const response = await onSubmit(fieldValue, isChecked);
                  setServerMessage(response.message);
                  if (response.ok) {
                    setFieldValue("");
                    setIsDirty(false);
                  } else {
                    setIsDirty(true);
                    setError(response.message);
                  }
                } catch (e) {
                  setIsDirty(true);
                  setError("An unexpected error occurred.");
                } finally {
                  setIsSubmitting(false);
                }
              }}
            >
              <div className={styles.inputButtonContainer}>
                <div className="formGroup">
                  <input
                    placeholder={emailPlaceHolder.placeholder}
                    value={fieldValue}
                    onChange={(e) => {
                      setFieldValue(e.target.value);
                      if (!validateEmail(e.target.value)) {
                        setError("Please enter a valid email");
                      } else {
                        setError("");
                      }
                    }}
                    className={styles.emailInput}
                    type="email"
                  />
                  {error && isDirty && (
                    <Disclaimer big>
                      {AlertIcon} {error}
                    </Disclaimer>
                  )}
                  {serverMessage && !isDirty && (
                    <Disclaimer>{serverMessage}</Disclaimer>
                  )}
                  <label className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      name="marketingCheckbox"
                      checked={isChecked}
                      onChange={(e) => {
                        setIsChecked(e.target.checked);
                      }}
                    />
                    {newsletterConsentText(
                      newsletterLang || "en",
                      styles.tncLink,
                    )}
                  </label>
                </div>
                <span className={styles.signupButton}>
                  <Button
                    variant="secondary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {newsletterCtaText}
                  </Button>
                </span>
                {afterTextInput && (
                  <div className={styles.afterTextInput}>
                    <p>{afterTextInput}</p>
                  </div>
                )}
                <p className={styles.termsAndConditions}>
                  {newsletterTermsText(newsletterLang || "en", styles.tncLink)}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
