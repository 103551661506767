import { BeforeAndAfter } from "@sphtech/dmg-design-system";

import {
  extractCrop,
  ResolvedCUEElement,
  ResolvedImageCompareAfterCUEElement,
  ResolvedImageCompareBeforeCUEElement,
} from "../elements";

const StorylineBeforeAndAfter = ({
  element,
  bodyMaxWidth,
}: {
  element: ResolvedCUEElement;
  bodyMaxWidth: number;
}) => {
  const beforeElement = element.children?.find(
    (e): e is ResolvedImageCompareBeforeCUEElement =>
      e.type === "image_compare_before",
  );
  const afterElement = element.children?.find(
    (e): e is ResolvedImageCompareAfterCUEElement =>
      e.type === "image_compare_after",
  );

  if (!beforeElement || !afterElement) {
    return null;
  }
  const beforeOriginalCrop = extractCrop(beforeElement.relation, "original");
  const afterOriginalCrop = extractCrop(afterElement.relation, "original");
  if (!beforeOriginalCrop || !afterOriginalCrop) {
    console.error("Image Compare Pictures Missing");
    console.log(beforeElement, afterElement);
    return;
  }
  const fields = beforeElement.additionalFields;

  return (
    <BeforeAndAfter
      orientation={
        fields.orientation?.value === "Vertical" ? "vertical" : "horizontal"
      }
      beforeImage={beforeOriginalCrop}
      afterImage={afterOriginalCrop}
      displayWidth={bodyMaxWidth}
      initialOffset={
        fields.offsetPercentage?.value !== undefined
          ? +fields.offsetPercentage.value
          : undefined
      }
    />
  );
};

export default StorylineBeforeAndAfter;
