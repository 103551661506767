import "@sphtech/dmg-design-system/dist/theme-hbs.css";
import "@sphtech/dmg-design-system/assets/hbs/fonts.css";

import { configHome, configSite } from "@pub/config";
import { BaseLayout } from "@sphtech/dmg-design-system";
import Newsticker from "@src/app/components/Modules/Newsticker/Newsticker";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { configHeader } from "./config/configHeader";
import logoSvgSource from "./logo.svg?raw";
import { RoutePaths } from "./routePaths";

type HBSBaseLayoutProps = {
  ArticleTitle?: string;
  children: React.ReactNode;
  ads?: boolean;
  leaderboard?: boolean;
  skipMobileBanner?: boolean;
};

/**
 * Reusable Base layout specific to HBS.
 */
const HBSBaseLayout = ({
  ArticleTitle,
  children,
  ads = true,
  leaderboard = true,
  skipMobileBanner,
}: HBSBaseLayoutProps) => {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  return (
    <BaseLayout
      ArticleTitle={ArticleTitle}
      ads={ads}
      leaderboard={leaderboard}
      NewsTicker={<Newsticker />}
      RoutePaths={RoutePaths}
      svgSource={logoSvgSource}
      siteName={configSite.name}
      mciText={
        <span suppressHydrationWarning>
          MDDI (P) 031/11/2024. Published by SPH Media Limited, Co. Regn. No.
          202120748H. Copyright © {currentYear} SPH Media Limited. All rights
          reserved.
        </span>
      }
      socialIconProps={configHeader.socialMedia}
      megaMenuProps={{
        NewsLetterProps: configHeader.megaMenu.newsletter,
        CloseButtonProps: configHeader.megaMenu.closeButton,
      }}
      subscribeBoxButtonIcon={configHome.magazineListing?.ctaIcon}
      skipMobileBanner={skipMobileBanner}
      currentLocation={location}
    >
      <Helmet
        htmlAttributes={{
          lang: "en-SG",
        }}
      >
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#b30010" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="theme-color" content="#000000" />
      </Helmet>
      {children}
    </BaseLayout>
  );
};

export default HBSBaseLayout;
