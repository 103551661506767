import cx from "classnames";
import { ReactNode } from "react";
import { useCustomClassContext } from "../../../../hooks/useCustomClassContext/CustomClassContext";
import styles from "./heading.module.css";

type HeadingElement = "h1" | "h2" | "h3" | "h4" | "h5";

type Props = {
  CustomElement?: HeadingElement;
  children: ReactNode;
  nameZH?: string;
  headingUrl?: string | undefined;
  id?: string;
};

type HeadingReturnType = ({ children }: Props) => ReactNode;

function createHeading(
  DefaultElement: HeadingElement,
  className?: string,
): HeadingReturnType {
  return ({
    children,
    id,
    CustomElement: Element = DefaultElement,
    nameZH,
    headingUrl,
  }: Props): ReactNode => {
    const customClass = useCustomClassContext();

    if (nameZH) {
      const textENLength = children?.toString().split(" ")?.length ?? 0;

      return (
        <Element
          id={id}
          className={cx(
            className,
            styles.base,
            customClass?.heading?.headingBase,
          )}
        >
          <span
            className={cx(customClass?.heading?.headingSplit)}
            lang="en"
            data-length={textENLength > 3 ? "long" : "short"}
          >
            {headingUrl ? <a href={headingUrl}>{children}</a> : children}
          </span>

          <span
            className={cx(customClass?.heading?.headingSplit)}
            lang="zh"
            data-char={nameZH?.length}
          >
            {headingUrl ? <a href={headingUrl}>{nameZH}</a> : nameZH}
          </span>
        </Element>
      );
    }

    return (
      <Element id={id} className={cx(className, styles.base)}>
        {headingUrl ? <a href={headingUrl}>{children}</a> : children}
      </Element>
    );
  };
}

/**
 * Create comments so that it easier for the devs to identify.
 * Spaced apart based on styling.
 */

/** alias to H1 */
export const SectionTitle = createHeading("h1", styles.sectionTitle);

/** alias to H1 with H2 styling*/
export const Title = createHeading("h1", styles.title);
/** alias to H2 */
export const SubSectionTitle = createHeading("h2", styles.sectionTitle);

/** alias to H2 with H3 styling */
export const SubHeading = createHeading("h2", styles.subHeading1);
/** alias to H3 */
export const SubHeading2 = createHeading("h3", styles.subHeading2);

/** alias to H4 */
export const SubHeading3 = createHeading("h4", styles.subHeading3);

/** alias to H5 */
export const SubHeading4 = createHeading("h5", styles.subHeading4);

/** alternate Title - for bilingual */
export const TitleAlt = createHeading("h1", styles.titleAlt);
/** alternate to SubHeading3 - for bilingual */
export const SubHeading3Alt = createHeading("h4", styles.subHeading3Alt);
