export const ButtonWhiteArrowRight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="12"
    viewBox="0 0 22 12"
    fill="none"
  >
    <path
      d="M22 6L12 0.226496L12 11.7735L22 6ZM1.10429e-07 7L13 7L13 5L-1.10429e-07 5L1.10429e-07 7Z"
      fill="white"
    />
  </svg>
);

export const ctaIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53"
    height="52"
    viewBox="0 0 53 52"
    fill="none"
  >
    <circle cx="26.5" cy="26" r="26" fill="#B30010" />
    <path
      d="M32.5 26L22.5 20.2265V31.7735L32.5 26ZM0.5 27H23.5V25H0.5V27Z"
      fill="white"
    />
  </svg>
);
