import { RoutePathsType } from "../types/routePaths";

export const RoutePaths: RoutePathsType = {
  NavBar: {
    primaryLinks: [
      {
        name: "Fashion",
        url: "/fashion",
      },
      {
        name: "Jewels & Watches",
        url: "/jewels-watches",
      },
      {
        name: "Beauty",
        url: "/beauty",
      },
      {
        name: "Celebrity",
        url: "/celebrity",
      },
      {
        name: "Lifestyle",
        url: "/lifestyle",
      },
    ],
    utilityLinks: [
      {
        id: "magazineIssues", // allow overwrite of name and url from data
        name: "Subscribe",
        url: "https://subscribe.sph.com.sg/package/subscribe-harpers-bazaar-singapore/",
        as: "button",
        variant: "primary",
        target: "_blank",
      },
    ],
    searchBarLink: {
      name: "Search",
      url: "/search",
    },
    mobileUtilityLinks: {
      // login: {
      //   name: "Login",
      //   url: "/",
      // },
    },
  },
  Footer: [
    {
      text: "About Us",
      url: "/about-us",
    },
    {
      text: "Contact Us & Feedback",
      url: "https://www.sph.com.sg/contact-us/",
      target: "_blank",
    },
    {
      text: "Advertise With Us",
      url: "https://www.sph.com.sg/advertising-solutions/",
      target: "_blank",
    },
    {
      text: "Terms and Conditions",
      url: "https://www.sph.com.sg/legal/website_tnc/",
      target: "_blank",
    },
    {
      text: "Privacy Policy",
      url: "https://www.sph.com.sg/legal/sph_privacy/",
      target: "_blank",
    },
    {
      text: "PDPA",
      url: "https://www.sph.com.sg/legal/pdpa/",
      target: "_blank",
    },
  ],
  MegaMenu: [
    {
      text: "Fashion",
      url: "/fashion",
      placement: "left",
      subMenu: [
        {
          text: "News & Trends",
          url: "/fashion/news-trends",
        },
        {
          text: "Shopping",
          url: "/fashion/shopping",
        },
        {
          text: "Runway",
          url: "/fashion/runway",
        },
        {
          text: "Street Style",
          url: "/fashion/street-style",
        },
      ],
    },
    {
      text: "Jewels & Watches",
      url: "/jewels-watches",
      placement: "right",
      subMenu: [
        {
          text: "News & Trends",
          url: "/jewels-watches/news-trends",
        },
        {
          text: "Jewellery",
          url: "/jewels-watches/jewellery",
        },
        {
          text: "Watches",
          url: "/jewels-watches/watches",
        },
      ],
    },
    {
      text: "Beauty",
      url: "/beauty",
      placement: "right",
      subMenu: [
        {
          text: "News & Trends",
          url: "/beauty/news-trends",
        },
        {
          text: "Hair",
          url: "/beauty/hair",
        },
        {
          text: "Skincare",
          url: "/beauty/skincare",
        },
        {
          text: "Makeup",
          url: "/beauty/makeup",
        },
        {
          text: "Beauty Awards",
          url: "/topics/beauty-awards",
        },
        {
          text: "Spa Awards",
          url: "/topics/spa-awards",
        },
      ],
    },
    {
      text: "Celebrity",
      url: "/celebrity",
      placement: "left",
      subMenu: [],
    },
    {
      text: "Lifestyle",
      url: "/lifestyle",
      placement: "left",
      subMenu: [],
    },
    {
      text: "Videos",
      url: "/videos",
      placement: "left",
      subMenu: [],
    },
    {
      text: "BAZAAR Man",
      url: "/bazaar-man",
      placement: "left",
      subMenu: [],
    },
    {
      text: "BAZAAR NewGen",
      url: "/newgen",
      placement: "left",
      subMenu: [],
    },
    {
      text: "Events",
      url: "/events",
      placement: "left",
      subMenu: [],
    },
  ],
};
