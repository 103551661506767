import {
  CUEAtomEntryGenericArticle,
  CUEElementRelation,
} from "@src/app/types/Opensearch";

import { ResolvedCUEElement } from "../../elements";
import RelatedArticleInline from "../RelatedArticleInline";

type RelationProps = {
  elements: ResolvedCUEElement;
};

const isAtomEntry = (
  ele?: CUEElementRelation,
): ele is CUEAtomEntryGenericArticle =>
  !!ele && Object.prototype.hasOwnProperty.call(ele, "id");

function GroupedRelation({ elements }: RelationProps) {
  if (elements.type !== "grouped_relation") {
    return null;
  }

  const relationIds = elements.children
    ?.map(
      (items) =>
        (items.type === "relation" &&
          isAtomEntry(items.relation) &&
          items.relation.id) ||
        "",
    )
    .filter((id) => id);

  if (!relationIds?.length) {
    return null;
  }

  const htmlAnchor = elements.additionalFields.htmlAnchor?.value;

  return <RelatedArticleInline ids={relationIds} htmlAnchor={htmlAnchor} />;
}

export default GroupedRelation;
