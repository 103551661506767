export const maybeScrollToId = (
  id?: string | null,
  behavior?: ScrollIntoViewOptions["behavior"],
) => {
  const targetElement = id && document.getElementById(id);

  if (targetElement) {
    targetElement.scrollIntoView({
      behavior: behavior ?? "smooth",
      block: "start",
    });
    return true;
  }
  return false;
};

export const onClickMaybeScrollToHref = (e: React.MouseEvent) => {
  if (e.metaKey || e.ctrlKey || e.shiftKey) {
    return;
  }

  const hrefAttribute = e.currentTarget.getAttribute("href");

  if (hrefAttribute?.at(0) === "#" && maybeScrollToId(hrefAttribute.slice(1))) {
    if (e.currentTarget.getAttribute("target") !== "_blank") {
      e.preventDefault();
    }
  }
};
