import { configSite } from "@pub/config";
import { NewsTicker } from "@sphtech/dmg-design-system";
import { NewsTickerResponse } from "@src/app/types/ServerCustomContext";
// import { SHOW_DEBUG_INFO } from "@src/app/constants";
import { ReactNode } from "react";
import useSWR from "swr";

const NEWSTICKER_API = "/_plat/api/newsticker";

function Newsticker(): ReactNode {
  const { data, error, isLoading } = useSWR<NewsTickerResponse, unknown>(
    NEWSTICKER_API,
  );

  return (
    !error &&
    // display empty grey ticker as placeholder while loading / SSR
    (isLoading || !data?.results || !!data.results.length) && (
      <NewsTicker
        // placeholder without any text
        sourceFrom={
          isLoading
            ? ""
            : (configSite.newsticker?.label ?? "From The Straits Times |")
        }
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          window.SPHMGTM.cmd.push(
            `send=${JSON.stringify({ type: "event", category: "Ticker", action: "click", label: event.currentTarget.getAttribute("href") })}`,
          );
        }}
        duration={configSite.newsticker?.duration ?? 100}
        items={data?.results || []}
      />
    )
  );
}

export default Newsticker;
