type goToPositionProps = {
  container: HTMLDivElement;
  currentPosition: number;
  newPosition: number | "next" | "previous";
  isLooping?: boolean;
};

export const goToPosition = ({
  container,
  currentPosition,
  newPosition,
  isLooping,
}: goToPositionProps) => {
  const containerRect = container.getBoundingClientRect();
  const childrenBoundingClientRects = Array.from(container.children).map(
    (child) => child.getBoundingClientRect(),
  );

  /**
   * stageRight item is the first item NOT fully visible
   */
  let stageRight = childrenBoundingClientRects.findIndex(
    (el) => el.right > containerRect.right,
  );
  /* If current item occupies the entire stage or more */
  if (stageRight === currentPosition) {
    stageRight = stageRight + 1;
  }

  /**
   * stageLeft item is the first item fully visible,
   * if scrolling back a whole container width
   */
  let stageLeft = childrenBoundingClientRects.findIndex(
    (el) => el.left >= containerRect.left - containerRect.width,
  );
  /* If current item occupies the entire stage or more */
  if (stageLeft === currentPosition) {
    stageLeft = stageLeft - 1;
  }

  let safeNewPosition =
    newPosition === "next"
      ? stageRight
      : newPosition === "previous"
        ? stageLeft
        : newPosition;

  /**
   * This calculates the safeLastIndex which should be the leftmost fully visible item,
   * when the carousel is fully translated to the right.
   */
  const rightmostPosition = childrenBoundingClientRects.at(-1)?.right ?? 0;
  const safeLastPosition = rightmostPosition - containerRect.width;
  const safeLastIndex = childrenBoundingClientRects.findIndex(
    (el) => el.left >= safeLastPosition,
  );

  if (safeNewPosition > safeLastIndex) {
    safeNewPosition =
      isLooping && currentPosition >= safeLastIndex ? 0 : safeLastIndex;
  } else if (safeNewPosition <= 0) {
    safeNewPosition = isLooping && currentPosition === 0 ? safeLastIndex : 0;
  }

  const currentItemRect =
    container.children[safeNewPosition]?.getBoundingClientRect();
  if (!currentItemRect) return;

  container.scrollTo({
    left: container.scrollLeft + currentItemRect.left - containerRect.left,
    behavior: "smooth",
  });

  return {
    newPosition: safeNewPosition,
    hasPrevious: isLooping ? true : safeNewPosition > 0,
    hasNext: isLooping ? true : safeNewPosition < safeLastIndex,
  };
};
