import React, { useReducer } from "react";
import cx from "classnames";
import styles from "./beforeandafter.module.css";
import { ImageCrop } from "../../Utility/dataTypes";
import ResponsiveImage from "../../Element/ResponsiveImage";

type State = {
  rangeValue: number;
};

type Action = { type: "change"; payload: number };

type BeforeAndAfterProps = {
  orientation: "vertical" | "horizontal";
  beforeImage: ImageCrop;
  afterImage: ImageCrop;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  displayWidth?: number;
  initialOffset?: number;
};

export default function BeforeAndAfter({
  orientation = "horizontal",
  beforeImage,
  afterImage,
  onChange,
  displayWidth,
  initialOffset = 50,
}: BeforeAndAfterProps) {
  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "change":
        return {
          rangeValue: action.payload,
        };
      default:
        return state;
    }
  };

  const [{ rangeValue }, dispatch] = useReducer(reducer, {
    rangeValue: initialOffset,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "change", payload: Number(event.target.value) });

    if (onChange) onChange(event);
  };

  return (
    <div
      className={cx(
        styles.bnaContainer,
        orientation === "vertical" ? styles.bnaVertical : styles.bnaHorizontal,
      )}
    >
      {orientation === "vertical" ? (
        <div
          className={styles.bnaBeforeContainer}
          style={{ height: `${rangeValue}%` }}
        >
          <ResponsiveImage {...beforeImage} displayWidth={displayWidth} />
        </div>
      ) : (
        <div
          className={styles.bnaBeforeContainer}
          style={{ width: `${rangeValue}%` }}
        >
          <div
            style={{
              // reverse overflow cutoff percentage
              width: `${100 + ((100 - rangeValue) / rangeValue) * 100}%`,
            }}
          >
            <ResponsiveImage {...beforeImage} displayWidth={displayWidth} />
          </div>
        </div>
      )}

      <ResponsiveImage {...afterImage} displayWidth={displayWidth} />

      <input
        type="range"
        min={0}
        max={100}
        step={0.1}
        value={rangeValue}
        name="slider"
        onChange={handleChange}
        className={styles.bnaInputRange}
      />
      <div
        className={styles.bnaSliderHandleContainer}
        style={
          orientation === "vertical"
            ? { top: `${rangeValue}%` }
            : { left: `${rangeValue}%` }
        }
      >
        <svg
          fill="#333"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
        >
          <path d="M24,12l-5.7-5.7V11c-3.7,0-9,0-12.6,0V6.3L0,12l5.8,5.7V13c3.6,0,8.9,0,12.5,0v4.7L24,12z" />
        </svg>
      </div>
    </div>
  );
}
