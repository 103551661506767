import { ResolvedCUEElement, ResolvedSubHeadingCUEElement } from "../elements";

export function isSubHead(
  element: ResolvedCUEElement,
): element is ResolvedSubHeadingCUEElement {
  return element.type === "sub_head";
}

const TOC_HTML_ANCHORS = ["x-mag-table-of-contents", "x-mag-toc"];
export function isTableOfContents(element: ResolvedSubHeadingCUEElement) {
  const anchor = element.additionalFields.htmlAnchor?.value;
  return !!anchor && TOC_HTML_ANCHORS.includes(anchor);
}

function slugify(text: string) {
  return text
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9]+/g, "-");
}

/* Extract the anchor from the subhead element. Auto generate from text if missing.
 */
export function extractAnchor(element: ResolvedSubHeadingCUEElement) {
  return (
    element.additionalFields.htmlAnchor?.value ||
    (element.additionalFields.subhead?.value &&
      slugify(element.additionalFields.subhead.value))
  );
}

export function getTableOfContentsItems(allElements: ResolvedCUEElement[]) {
  return allElements
    .filter(isSubHead)
    .filter(
      (element) =>
        element.additionalFields.headings?.value === "h2" &&
        !isTableOfContents(element),
    );
}
