import styles from "./dropdown.module.css";

type Props = {
  label: string;
  name: string;
  id: string;
  value: string;
  options: { value: string; label: string }[];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
};

export default function Dropdown({
  label,
  name,
  id,
  value,
  options,
  onChange,
}: Props) {
  return (
    <label className={styles.label}>
      {label}
      <span className={styles.selectContainer}>
        <select
          name={name}
          id={id}
          value={value}
          className={styles.select}
          onChange={onChange}
        >
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
        <svg
          className={styles.arrow}
          width="15"
          height="9"
          viewBox="0 0 15 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 1L7.5 7L13.5 1"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </span>
    </label>
  );
}
