import ErrorLayout from "@app/layouts/ErrorLayout/ErrorLayout";
import MetaData from "@components/Modules/MetaData";
import PubBaseLayout from "@pub/BaseLayout";
import { configSite } from "@pub/config";
import { ResponsiveImage, Typography } from "@sphtech/dmg-design-system";
import {
  CustomContext,
  ResponseType,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import AuthorBlock from "@src/app/components/Modules/AuthorBlock/AuthorBlock";
import { STATIC_PAGE } from "@src/app/constants";
import { ResovledAuthorProfileElement } from "@src/app/layouts/Article/elements";
import Storyline from "@src/app/layouts/Article/Storyline";
import { StaticContent } from "@src/app/types/ServerCustomContext";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "./aboutus.module.css";

type AboutUsData = StaticContent;

export default function AboutUsPage(): ReactNode {
  const routeContext: CustomContext<TRouteWithRedirect<AboutUsData, string>> =
    useRouteContext();

  const { context: response } = routeContext;

  if (response.type !== ResponseType.SUCCESS) {
    return <ErrorLayout response={response} />;
  }

  const { processedElements, path, seoFields, ogFields, displaySetting } =
    response.payload;
  const { headline, headlineZH, coverImage, bodyElements } = processedElements;
  const { Heading } = Typography;
  const showHeadline = displaySetting?.displayHeadline !== "x-mag-none";

  const displayHeadline =
    showHeadline &&
    (headlineZH ? (
      <Heading.SectionTitle nameZH={headlineZH}>
        {headline}
      </Heading.SectionTitle>
    ) : (
      headline
    ));

  const profileElements = bodyElements.filter(
    (e): e is ResovledAuthorProfileElement => e.type === "relation_profile",
  );
  const splitIndex = profileElements[0]
    ? bodyElements.indexOf(profileElements[0])
    : bodyElements.length;
  const profileHeadline =
    profileElements.length && bodyElements[splitIndex - 1]?.type === "sub_head"
      ? bodyElements[splitIndex - 1]
      : undefined;

  const remainingElements = bodyElements.filter(
    (e) => e.type !== "relation_profile",
  );
  const elementsBefore = remainingElements.slice(
    0,
    splitIndex - (profileHeadline ? 1 : 0),
  );
  const elementsAfter = remainingElements.slice(splitIndex);

  const originalCrop = coverImage?.crops.original;
  const headlineBelowCoverImage = configSite.aboutUs?.headlineBelow;

  const title = seoFields.metaTitle || headline;
  const description = seoFields.metaDescription;
  const robots =
    seoFields.metaRobots === "1"
      ? "noindex, nofollow"
      : "index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1";

  return (
    <PubBaseLayout ads={false}>
      <MetaData
        title={title}
        type="WebPage"
        path={path}
        image={coverImage?.crops.landscape}
        description={description}
        robots={robots}
        ogFields={ogFields}
      />
      {originalCrop && (
        <div className={styles.headingBackgroundContainer}>
          <ResponsiveImage {...originalCrop} displayWidth={2000} />
          {!headlineBelowCoverImage && (
            <div className={styles.headingContainer}>{displayHeadline}</div>
          )}
        </div>
      )}
      {headlineBelowCoverImage && (
        <div className={styles.headingTextContainer}>
          <div className={styles.headingContainer}>{displayHeadline}</div>
        </div>
      )}
      <div className={cx(styles.bodyContainer, styles.gutter)}>
        <div className={cx(styles.storylineContainer, styles.intro)}>
          <Storyline
            bodyMaxWidth={800}
            path={path}
            typeName={STATIC_PAGE}
            excludeAds={true}
            elements={elementsBefore}
          />
        </div>
      </div>
      {!!profileElements.length && (
        <div className={styles.authorBlockContainer}>
          <AuthorBlock
            data={profileElements}
            profileHeadlineElement={profileHeadline}
          />
        </div>
      )}
      {!!elementsAfter.length && (
        <div className={cx(styles.bodyContainer, styles.gutter)}>
          <div className={cx(styles.storylineContainer, styles.contactUs)}>
            <Storyline
              bodyMaxWidth={800}
              path={path}
              typeName={STATIC_PAGE}
              excludeAds={true}
              elements={elementsAfter}
            />
          </div>
        </div>
      )}
    </PubBaseLayout>
  );
}
