import cx from "classnames";
import { ReactNode, useEffect, useRef } from "react";
import NaturalCarousel, {
  NaturalCarouselRef,
} from "../../Utility/NaturalCarousel/NaturalCarousel";
import styles from "./listgroup.module.css";

type ListGroupProps = {
  items: {
    text: string;
    url: string;
  }[];
  /* This highlights the activeTitle from the list */
  activeTitle?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

/**
 * This is our list group component. It aims to display a series of content.
 */
function ListGroup({ items, activeTitle, onClick }: ListGroupProps): ReactNode {
  const carouselRef = useRef<NaturalCarouselRef>(null);

  useEffect(() => {
    const foundItemIndex = items.findIndex((el) => el.text === activeTitle);

    // Subtracting 1 from index to roughly put the active item in the center
    carouselRef.current?.goTo(foundItemIndex - 1);
  }, [activeTitle, items]);

  return (
    <div className={styles.carouselContainer}>
      <NaturalCarousel
        isLooping={false}
        arrowSize={22}
        ref={carouselRef}
        showArrowsOnMobile
      >
        {items.map(({ text, url }, index) => (
          <a
            href={url}
            onClick={onClick}
            className={cx(
              styles.listGroupItem,
              activeTitle === text && styles.itemSelected,
            )}
            key={index}
          >
            {text}
          </a>
        ))}
      </NaturalCarousel>
    </div>
  );
}

export default ListGroup;
