import { ResolvedSubHeadingCUEElement } from "@app/layouts/Article/elements";
import { Typography } from "@sphtech/dmg-design-system";

import { Annotation } from "./Annotation/Annotation";
import { extractAnchor } from "./tableOfContentsUtils";

type SubHeadingProps = {
  element: ResolvedSubHeadingCUEElement;
};

const SubHeadingElement = ({ element }: SubHeadingProps) => {
  const { Heading, Body } = Typography;
  const htmlAnchor = extractAnchor(element);

  switch (element.additionalFields.headings?.value) {
    case "h2":
      return (
        <Heading.SubHeading id={htmlAnchor}>
          <Annotation element={element.additionalFields.subhead || element} />
        </Heading.SubHeading>
      );
    case "h3":
      return (
        <Heading.SubHeading2 id={htmlAnchor}>
          <Annotation element={element.additionalFields.subhead || element} />
        </Heading.SubHeading2>
      );
    case "h4":
      return (
        <Heading.SubHeading3 id={htmlAnchor}>
          <Annotation element={element.additionalFields.subhead || element} />
        </Heading.SubHeading3>
      );
    case "h5":
    case "h6": // SubHeading design only up to h5
      return (
        <Heading.SubHeading4 id={htmlAnchor}>
          <Annotation element={element.additionalFields.subhead || element} />
        </Heading.SubHeading4>
      );
    default:
      return (
        <Body id={htmlAnchor}>
          <Annotation element={element.additionalFields.subhead || element} />
        </Body>
      );
  }
};

export default SubHeadingElement;
