import {
  getGalleryItemInfo,
  ProcessedElements,
  ResolvedCUEElement,
  ResolvedEmbedCUEElement,
  ResolvedImageCUEElement,
  resolveImageCrop,
} from "@app/layouts/Article/elements";
import { configSingleStory, configSite } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  Arrow,
  EmbeddedGallery,
  FullScreenIcon,
  ShareIcon,
  useBodyScrollLock,
  useSidebarToggle,
} from "@sphtech/dmg-design-system";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import LightBoxPortal from "../LightBox";
import AppendedGalleryItem from "./AppendedGalleryItem";
import EmbeddedGalleryDescription from "./EmbeddedGalleryDescription";
import styles from "./EmbeddedGalleryElement.module.css";
import EmbeddedGalleryTitle from "./EmbeddedGalleryTitle";

type EmbeddedGalleryProps = {
  elements: ProcessedElements;
  id: string;
  path: string;
};

type EmbeddedGalleryItems = React.ComponentProps<
  typeof EmbeddedGallery
>["items"];

const isCustomHTML = (input?: ResolvedCUEElement) =>
  input?.type === "gallery_items_custom_html";

const isGalleryImage = (
  input?: ResolvedCUEElement,
): input is ResolvedImageCUEElement => input?.type === "gallery_items_image";

const EmbeddedGalleryElement = ({
  elements,
  id,
  path,
}: EmbeddedGalleryProps) => {
  const [isOpen, toggleLightBox] = useSidebarToggle(false);
  const [gotoItem, setgotoItem] = useState(0);
  useBodyScrollLock(isOpen);

  const galleryRef = useRef<HTMLDivElement | null>(null);
  const [searchParams] = useSearchParams();
  const eGalleryId = searchParams.get("egallery");
  const eSlide = Number.parseInt(searchParams.get("eslide") ?? "");

  const items = elements.bodyElements[0]?.children?.reduce(
    (acc: EmbeddedGalleryItems, item, index, arr) => {
      const customHTML = isCustomHTML(item) ? item : undefined;
      const image = isGalleryImage(item) ? item : undefined;
      const ele = customHTML || image;

      // When story elements are appended, it appears as the next item in the array
      const nextItem = arr.length > index + 2 ? arr[index + 1] : null;

      if (!ele) return acc;

      const videoUrl =
        customHTML &&
        customHTML.children?.find(
          (e): e is ResolvedEmbedCUEElement => e.type === "embed",
        )?.additionalFields.uri?.value;

      const resolvedImage = image && resolveImageCrop(image, "original");
      const info = getGalleryItemInfo(
        customHTML && customHTML.children ? customHTML.children : [ele],
      );

      return [
        ...acc,
        {
          title: (
            <EmbeddedGalleryTitle
              title={info?.title}
              targetUrl={info?.targetUrl}
            />
          ),
          description: info?.description?.value ? (
            <EmbeddedGalleryDescription description={info.description} />
          ) : null,
          alt: (resolvedImage && resolvedImage.alt) || "", //TODO fix no alt field in CUE.
          caption: (
            <div className={styles.embeddedGalleryCaption}>
              {resolvedImage && resolvedImage.credit}
            </div>
          ),
          videoUrl: videoUrl,
          image: resolvedImage || undefined,
          shareUrl: `${configSite.address}${path}?egallery=${id}`,
          // For Shop button, CTA
          appendedGalleryItem: nextItem && (
            <AppendedGalleryItem galleryItem={nextItem} />
          ),
        },
      ];
    },
    [],
  );

  useEffect(() => {
    if (galleryRef.current && eGalleryId && eGalleryId === id) {
      galleryRef.current.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  }, [eGalleryId, id]);

  useEffect(() => {
    handleLoading(id);
  });

  function handleFullScreen(index: number) {
    setgotoItem(index);
    toggleLightBox();
    window.postMessage(`lightbox-open=${index}`);
  }

  const onSlideChange = (n: number, s: string, galleryid: string) => {
    window.postMessage({
      embeddedgallery: { action: s, slide: n, target: galleryid },
    });
  };

  const onSlideClick = (n: number, s: string, galleryid: string) => {
    window.postMessage({
      embeddedgallery: { action: s, slide: n, target: galleryid },
    });
  };

  const handleLoading = (id: string) => {
    window.postMessage(`embeddedgallery-load=${id}`);
  };

  if (!items || items.length === 0) return null;

  return (
    <div
      ref={galleryRef}
      data-gallery-id={id}
      className={styles.embeddedGalleryContainer}
    >
      <EmbeddedGallery
        items={items}
        leftIcon={
          configSingleStory.embeddedGallery?.leftIcon || (
            <Arrow size={54} direction="left" />
          )
        }
        rightIcon={
          configSingleStory.embeddedGallery?.rightIcon || <Arrow size={54} />
        }
        srcWidth={655}
        srcHeight={425}
        actionbar={{
          onFullScreen: handleFullScreen,
          fullScreenIcon: FullScreenIcon,
          shareIcon: ShareIcon,
        }}
        Imu=""
        ImuInsertAt={999}
        slidePosition={eGalleryId === id && eSlide ? eSlide : 1}
        shareMedia={configSingleStory.gallery?.galleryItemShareMedia}
        onSlideChange={onSlideChange}
        onSlideClick={onSlideClick}
        errorSrc={placeholders.landscape.src}
      />
      {elements.bodyElements[0]?.children && (
        <LightBoxPortal
          isOpen={isOpen}
          elements={elements.bodyElements[0].children}
          gotoItem={gotoItem}
          close={toggleLightBox}
          ImuInsertAt={999}
        />
      )}
    </div>
  );
};

export default EmbeddedGalleryElement;
