export enum SOCIAL_MEDIA {
  YOUTUBE = "youtube",
  INSTAGRAM = "instagram",
  TIKTOK = "tiktok",
  FACEBOOK = "facebook",
  TWITTER = "twitter",
  VIEMO = "vimeo",
  SPOTIFY = "spotify",
  NONE = "none",
}

const getSocialMedia = (parsedUrl: URL | undefined) => {
  const hostname = parsedUrl?.hostname.toLowerCase() || "";
  switch (hostname) {
    case "youtube.com":
    case "www.youtube.com":
    case "m.youtube.com":
    case "youtu.be":
      return SOCIAL_MEDIA.YOUTUBE;
    case "instagram.com":
    case "www.instagram.com":
    case "m.instagram.com":
      return SOCIAL_MEDIA.INSTAGRAM;
    case "tiktok.com":
    case "www.tiktok.com":
    case "m.tiktok.com":
      return SOCIAL_MEDIA.TIKTOK;
    case "facebook.com":
    case "www.facebook.com":
    case "m.facebook.com":
      return SOCIAL_MEDIA.FACEBOOK;
    case "www.twitter.com":
    case "twitter.com":
    case "x.com":
    case "www.x.com":
      return SOCIAL_MEDIA.TWITTER;
    case "vimeo.com":
    case "www.vimeo.com":
      return SOCIAL_MEDIA.VIEMO;
    case "open.spotify.com":
    case "embed.spotify.com":
      return SOCIAL_MEDIA.SPOTIFY;
    default:
      return SOCIAL_MEDIA.NONE;
  }
};

const getParsedUrl = (url?: string) => {
  try {
    return url ? new URL(url) : undefined;
  } catch (e) {
    return undefined;
  }
};

const amendEmbedUrl = (
  url?: string,
  pathAmends?: Record<string, string>,
  loading?: "lazy" | "eager",
) => {
  const parsedUrl = getParsedUrl(url);
  if (!parsedUrl) return url;

  const pathAmend = parsedUrl?.hostname && pathAmends?.[parsedUrl?.hostname];
  if (pathAmend) {
    parsedUrl.pathname = parsedUrl.pathname + pathAmend;
  }
  if (getSocialMedia(parsedUrl) === SOCIAL_MEDIA.YOUTUBE) {
    parsedUrl.searchParams.set("enablejsapi", "1");
    if (loading !== "eager") {
      // use facade
      parsedUrl.searchParams.set("autoplay", "1");
      parsedUrl.searchParams.set("playsinline", "1");
    }
  }

  return parsedUrl.toString();
};

export function getYoutubeId(url?: string | URL) {
  const parsedUrl = typeof url === "string" ? getParsedUrl(url) : url;
  if (!parsedUrl) return;

  const videoId =
    parsedUrl.searchParams.get("v") ||
    parsedUrl.pathname
      .split("/")
      .find(
        (segment) => segment && !["shorts", "watch", "embed"].includes(segment),
      );
  return videoId;
}

export function convertToEmbed(
  url?: string,
  pathAmends?: Record<string, string>,
  loading?: "lazy" | "eager",
): [string, SOCIAL_MEDIA] {
  const parsedUrl = getParsedUrl(url);
  if (!url || !parsedUrl) return [url || "", SOCIAL_MEDIA.NONE];

  if (url.includes("/embed/")) {
    return [
      amendEmbedUrl(url, pathAmends, loading) || "",
      getSocialMedia(parsedUrl),
    ];
  }

  let embedUrl = url;
  const socialMedia = getSocialMedia(parsedUrl);
  switch (socialMedia) {
    case SOCIAL_MEDIA.YOUTUBE: {
      const videoId = getYoutubeId(parsedUrl);
      const startTime = parsedUrl.searchParams.get("t");

      embedUrl = `https://www.youtube.com/embed/${videoId}${
        startTime ? `?start=${startTime}` : ""
      }`;
      break;
    }
    case SOCIAL_MEDIA.INSTAGRAM: {
      const postCode = parsedUrl.pathname
        .split("/")
        .find((segment) => segment && !["p", "reel"].includes(segment));
      embedUrl = `https://www.instagram.com/p/${postCode}/embed/?omitscript=true`;
      break;
    }
    case SOCIAL_MEDIA.TIKTOK: {
      const postCode = url.split("/").pop();
      embedUrl = `https://www.tiktok.com/embed/v2/${postCode}`;
      break;
    }
    case SOCIAL_MEDIA.FACEBOOK: {
      if (url.includes("facebook.com/plugins/post.php")) break;
      embedUrl = `https://www.facebook.com/plugins/post.php?href=${encodeURIComponent(url)}`;
      break;
    }
    case SOCIAL_MEDIA.TWITTER: {
      embedUrl = `https://x.com${parsedUrl.pathname}`;
      break;
    }
    case SOCIAL_MEDIA.VIEMO: {
      const videoId = parsedUrl.pathname.split("/").pop();
      embedUrl = `https://player.vimeo.com/video/${videoId}`;
      break;
    }
    case SOCIAL_MEDIA.SPOTIFY: {
      parsedUrl.hostname = "embed.spotify.com";
      embedUrl = parsedUrl.href;
      break;
    }
  }

  return [amendEmbedUrl(embedUrl, pathAmends, loading) || "", socialMedia];
}

export const getAspectRatio = (
  originalSrc: string | URL,
  socialMedia: SOCIAL_MEDIA,
) => {
  const parsedOriginalUrl =
    typeof originalSrc === "string" ? getParsedUrl(originalSrc) : originalSrc;

  switch (socialMedia) {
    case SOCIAL_MEDIA.INSTAGRAM:
    case SOCIAL_MEDIA.TIKTOK:
    case SOCIAL_MEDIA.FACEBOOK:
    case SOCIAL_MEDIA.TWITTER:
      return 9 / 16;
    case SOCIAL_MEDIA.YOUTUBE:
      return parsedOriginalUrl?.pathname.includes("shorts") ? 9 / 16 : 16 / 9;
    case SOCIAL_MEDIA.VIEMO:
    default:
      return 16 / 9;
  }
};

type EmbedScriptMap = Record<
  SOCIAL_MEDIA,
  { script: string; callback?: () => void } | undefined
>;

declare global {
  interface Window {
    instgrm: {
      Embeds: {
        process: () => void;
      };
    };
    FB: {
      XFBML: {
        parse: () => void;
      };
    };
  }
}

export function setupResizeListener() {
  if (typeof window === "undefined") {
    // skip during server rendering
    return;
  }
  window.addEventListener("message", (event) => {
    // origin check for security
    // - currently only support instagram MEASURE message
    if (event.origin == "https://www.instagram.com") {
      document.querySelectorAll("iframe").forEach((iframe) => {
        if (iframe.contentWindow === event.source) {
          const data = JSON.parse(event.data);
          if (data.type === "MEASURE") {
            iframe.setAttribute("height", data.details.height);
          }
        }
      });
    }
    // contest.sph.com.sg also support passing height and width via postMessage
    if (event.origin == "https://contest.sph.com.sg") {
      document.querySelectorAll("iframe").forEach((iframe) => {
        if (
          iframe.contentWindow === event.source &&
          typeof event.data == "object"
        ) {
          const data = {
            height: event.data.height || null,
            width: event.data.width || null,
          };
          if (data.height) iframe.setAttribute("height", data.height);
          if (data.width) iframe.setAttribute("width", data.width);
        }
      });
    }
  });
}

export const embedScriptRecord: Partial<EmbedScriptMap> = {
  [SOCIAL_MEDIA.VIEMO]: {
    script: "https://player.vimeo.com/api/player.js",
  },
  [SOCIAL_MEDIA.FACEBOOK]: {
    script: "https://connect.facebook.net/en_GB/sdk.js#xfbml=0&version=v19.0",
    callback: () => window.FB.XFBML.parse(),
  },
};

export const isPortraitVideo = (url?: string) => {
  const parsedUrl = getParsedUrl(url);
  return (
    !!parsedUrl && getAspectRatio(parsedUrl, getSocialMedia(parsedUrl)) < 1
  );
};
