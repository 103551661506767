/**
 * Company: SPHMedia
 * Description: Search Form
 */

import { configSearch } from "@pub/config";
import { SmallSearchIcon } from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactElement, useEffect, useState } from "react";

import styles from "./searchform.module.css";

type SearchFormProps = {
  onSubmit: (fieldValue: string) => void;
  value?: string;
};

export default function SearchForm({
  onSubmit,
  value = "",
}: SearchFormProps): ReactElement {
  // Add component logic and state here
  const [fieldValue, setFieldValue] = useState(value);
  const [isDirty, setIsDirty] = useState(false);
  useEffect(() => {
    if (!isDirty) {
      setFieldValue(value);
    }
  }, [isDirty, value]);

  return (
    <form
      className={styles.searchBox}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(fieldValue);
        setIsDirty(false);
      }}
    >
      <span className={cx(styles.searchBoxChild, styles.searchBoxIcon)}>
        {SmallSearchIcon}
      </span>
      <input
        type="text"
        placeholder={configSearch.placeholder ?? "Search by keyword"}
        maxLength={1000}
        className={cx(styles.searchBoxTextInput, styles.searchBoxChild)}
        value={fieldValue}
        onChange={(e) => {
          setIsDirty(true);
          setFieldValue(e.target.value);
        }}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={true}
      />
    </form>
  );
}
