function setCookie(name: string, value: string, expiresDays: number) {
  const expires = new Date();
  expires.setTime(expires.getTime() + expiresDays * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}

const COOKIE_PREFIX = "sphMagMobileBanner";

function safeCookieKey(cookieKey: string) {
  return cookieKey.replace(/[^\w]/g, "");
}

/**
 * create self executing function for HTML head to instantly hide mobile banner based on cookie
 */
export function createHideMobileBannerSEF(cookieKey = "") {
  return `(function(){if(document.cookie.includes("${COOKIE_PREFIX}${safeCookieKey(cookieKey)}=0")){document.documentElement.setAttribute("data-hide-mobile-banner","");}})()`;
}

export function setMobileBannerHiddenCookie(
  hiddenDays: number,
  cookieKey = "",
) {
  setCookie(`${COOKIE_PREFIX}${safeCookieKey(cookieKey)}`, "0", hiddenDays);
  // also immediately hide it
  document.documentElement.setAttribute("data-hide-mobile-banner", "");
}
