import ResponsiveImage from "../../Element/ResponsiveImage";
import { ImageCrop } from "../../Utility/dataTypes";
import styles from "./authorprofile.module.css";
import cx from "classnames";

import * as Typography from "../../Element/Typography";
import { useSidebarToggle as useToggle } from "../../../hooks";
import { useEffect, useRef, useState } from "react";
import Arrow from "../../Element/Arrow/Arrow";
import { useCustomClassContext } from "../../../hooks/useCustomClassContext/CustomClassContext";

export type AuthorProfileProps = {
  image?: ImageCrop;
  name: string;
  title: string;
  description: string;
  link?: string;
  lineClamp?: boolean;
  h1?: boolean;
  showVerticalLine?: boolean;
};

const { Heading } = Typography;

export default function AuthorProfile({
  image,
  title,
  description,
  name,
  link,
  lineClamp = true,
  h1 = false,
  showVerticalLine = false,
}: AuthorProfileProps) {
  const [readMore, setReadMore] = useToggle(false);
  const element = useRef<HTMLDivElement>(null);
  const [isLineClamped, setIsLineClamped] = useState(false);

  useEffect(() => {
    if (lineClamp && element.current) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setIsLineClamped(
            entry.target.scrollHeight > entry.target.clientHeight,
          );
        }
      });

      if (readMore) {
        observer.unobserve(element.current);
      } else {
        observer.observe(element.current);
      }

      return () => {
        observer.disconnect();
      };
    }
  }, [element, readMore, lineClamp]);

  const HElement = h1 ? Heading.SectionTitle : Heading.SubHeading2;
  const customClass = useCustomClassContext();

  return (
    <div className={styles.container}>
      <div
        className={cx(
          styles.image,
          showVerticalLine && customClass?.authorProfile?.verticalLine,
        )}
      >
        {image && <ResponsiveImage {...image} />}
      </div>
      <div className={styles.details}>
        <HElement>
          {link ? (
            <a href={link} className={styles.link}>
              {name}
            </a>
          ) : (
            name
          )}
        </HElement>
        <p className={styles.jobTitle}>{title}</p>
        <p
          className={cx(
            styles.description,
            lineClamp && styles.lineClamp,
            readMore && styles.expanded,
          )}
          ref={element}
        >
          {description}
        </p>
        {isLineClamped && (
          <button onClick={setReadMore} className={styles.readmore}>
            {readMore ? "Show less" : "Show more"}
            <Arrow direction={readMore ? "up" : "down"} size={15} />
          </button>
        )}
      </div>
    </div>
  );
}
