import { configHome, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import { AnchorBlock, Typography } from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { HomeLayoutProps } from "./utils";

const { Heading } = Typography;

type Props = {
  data: HomeLayoutProps["data"];
};

export default function HomeAnchorBlock({ data }: Props): ReactNode {
  if (!data.AnchorBlockProps) {
    return;
  }
  return (
    <div className={cx(styles.anchorBlockContainer, styles.bodyContainer)}>
      {configHome.anchorBlock?.sectionTitleEN && (
        <div className={styles.anchorBlockSectionTitle}>
          <Heading.SubSectionTitle
            nameZH={configHome.anchorBlock.sectionTitleZH}
          >
            {configHome.anchorBlock.sectionTitleEN}
          </Heading.SubSectionTitle>
        </div>
      )}

      <AnchorBlock
        {...data.AnchorBlockProps}
        placeholder={placeholders.landscape}
        loading="eager"
        CustomHeadingElement="h1"
        hideBreadCrumb={configHome.anchorBlock?.hideBreadCrumb}
        hideVerticalLine={configHome.anchorBlock?.hideVerticalLine}
        mobileCrop={configTeaser.anchorBlock?.mobileCrop}
      />
    </div>
  );
}
