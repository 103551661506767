import React, { ReactElement } from "react";
import style from "./body.module.css";
import cx from "classnames";

type BodyProps = {
  Element?: "p" | "span";
  size?: "default" | "small";
  children?: React.ReactNode;
  id?: string;
};

export default function Body({
  children,
  size = "default",
  Element = "p",
  id,
}: BodyProps): ReactElement {
  return (
    <Element className={cx(style.base, style[size])} id={id}>
      {children}
    </Element>
  );
}
