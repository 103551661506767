import { INTERNAL_LINKS } from "@app/constants";
import { configSingleStory } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  ResponsiveImage,
  Typography,
  useCustomClassContext,
} from "@sphtech/dmg-design-system";
import cx from "classnames";

import { ResolvedImageCUEElement, resolveImageCrop } from "../elements";
import styles from "../Storyline.module.css";
import { Annotation } from "./Annotation/Annotation";
import { getParsedUrl } from "./utils";

const Image = ({
  element,
  bodyMaxWidth,
}: {
  element: ResolvedImageCUEElement;
  bodyMaxWidth: number;
}) => {
  const customClass = useCustomClassContext();

  const crop = resolveImageCrop(
    element,
    configSingleStory.storylineImage?.fixedCropSelection,
  );
  if (!crop) return null;

  const caption = element.additionalFields.displayCaption?.value
    ? element.additionalFields.caption
    : undefined;
  const description = element.additionalFields.description;
  const { imageLink } = element.additionalFields;

  const imageElement = (
    <ResponsiveImage
      src={crop.src}
      alt={crop.alt}
      srcWidth={crop.srcWidth}
      srcHeight={crop.srcHeight}
      displayWidth={bodyMaxWidth}
      loading="lazy"
      errorSrc={placeholders.landscape.src}
    />
  );

  const linkHostname = getParsedUrl(imageLink?.value)?.hostname;
  const isInternalLink =
    !!linkHostname && INTERNAL_LINKS.includes(linkHostname);
  const htmlAnchor = element.additionalFields.htmlAnchor?.value;

  return (
    <figure
      id={htmlAnchor}
      className={cx(
        styles.figure,
        // disabled, need to discuss proper solution
        // crop.srcHeight > crop.srcWidth && styles.figureNarrow,
      )}
    >
      {configSingleStory.storylineImage?.hyperlink !== false &&
      imageLink?.value ? (
        <a
          href={imageLink.value}
          target="_blank"
          rel={
            isInternalLink
              ? "noreferrer noopener"
              : "noreferrer nofollow noopener"
          }
        >
          {imageElement}
        </a>
      ) : (
        imageElement
      )}
      {(caption?.value || description?.value || crop.credit) && (
        <figcaption className={styles.figureCaptions}>
          {caption && (
            <p
              className={cx(
                styles.imageCaption,
                customClass?.figureCaptions?.imageCaption,
              )}
            >
              <Annotation element={caption} />
            </p>
          )}
          {description && (
            <div className={styles.imageDescription}>
              <Typography.Body>
                <Annotation element={description} />
              </Typography.Body>
            </div>
          )}
          {crop.credit && (
            <Typography.Disclaimer>{crop.credit}</Typography.Disclaimer>
          )}
        </figcaption>
      )}
    </figure>
  );
};

export default Image;
