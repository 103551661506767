import { CSSProperties } from "react";
import CloseIcon from "../../Element/CloseIcon/CloseIcon";
import styles from "./MobileBanner.module.css";

type Props = {
  href: string;
  dangerouslySetInnerHTML: {
    __html: string;
  };
  onClose?: () => void;
  contentListOpts?: {
    backgroundColor?: string;
    color?: string;
    showOnDesktop?: string;
  };
};

export default function MobileBanner({
  href,
  onClose,
  dangerouslySetInnerHTML,
  contentListOpts,
}: Props) {
  const style: CSSProperties & {
    "--mobile-banner-mobile-up-display"?: string;
    "--mobile-banner-background-color"?: string;
    "--mobile-banner-color"?: string;
  } = {};

  style["--mobile-banner-mobile-up-display"] =
    contentListOpts?.showOnDesktop && "flex";
  style["--mobile-banner-background-color"] = contentListOpts?.backgroundColor;
  style["--mobile-banner-color"] = contentListOpts?.color;

  return (
    <div className={styles.container} style={style}>
      <a
        className={styles.link}
        href={href}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      />
      {onClose && (
        <button className={styles.close} onClick={onClose}>
          <CloseIcon size={12} strokeWidth={1} />
        </button>
      )}
    </div>
  );
}
