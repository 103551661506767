import ContentHubLayout from "@app/layouts/ContentHub/ContentHubLayout";
import ErrorLayout from "@app/layouts/ErrorLayout/ErrorLayout";
import { ContentHubData } from "@app/types/ServerCustomContext";
import AdSettings from "@app/utils/AdSettings";
import { mapTeaserDataToLatestProps } from "@app/utils/mapTeaserDataToProps";
import MetaData from "@components/Modules/MetaData";
import PubBaseLayout from "@pub/BaseLayout";
import { GlobalBaseData } from "@sphtech/dmg-design-system";
import {
  CustomContext,
  ResponseType,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { useLocation } from "react-router-dom";

export default function ContentHub() {
  const routeContext: CustomContext<
    TRouteWithRedirect<ContentHubData & GlobalBaseData, string>
  > = useRouteContext();

  // Context from server.ts
  const { context: response } = routeContext;
  const location = useLocation();

  if (response.type !== ResponseType.SUCCESS) {
    return <ErrorLayout response={response} />;
  }

  const responseData = response.payload;

  return (
    <PubBaseLayout>
      <MetaData {...responseData.metaData} />
      <AdSettings
        pageType="contenthub"
        sectionName={responseData.name}
        adUnits="lb1,lb2,catfish,prestitial"
        pageTitle={responseData.name}
        currentLocation={location}
        StoryData={{ id: responseData.id }}
      />
      <ContentHubLayout
        data={{
          id: responseData.id,
          name: responseData.name,
          nameZH: responseData.nameZH,
          path: response.payload.path,
          coverImage: responseData.coverImage,
          editorsNote: responseData.editorsNote,
          featuredSection: responseData.featuredSection,
          socialVideo: responseData.socialVideo,
          topStories: responseData.topStories,
          listedStories:
            responseData.listedStories &&
            responseData.listedStories.map((teaser) =>
              mapTeaserDataToLatestProps(teaser),
            ),
          tagSlugs: responseData.tagSlugs,
          storyPostData: responseData.storyPostData,
        }}
      />
    </PubBaseLayout>
  );
}
