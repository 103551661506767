import { configHome, configTeaser, DataSource } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  AnchorBlock,
  ContentBlock,
  LatestArticle,
  SectionContext,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import sharedStyles from "../homelayout.module.css";
import styles from "./HomeContentBlockGridAreas.module.css";
import { extractHomeContentData, HomeLayoutProps } from "./utils";
type Props = {
  data: HomeLayoutProps["data"];
  dataSource: DataSource;
  alternateBg?: boolean;
};

export default function HomeContentBlockGridAreas({
  data: allData,
  dataSource,
}: Props): ReactNode {
  const data = extractHomeContentData(allData, dataSource);

  if (!data?.items.length) return null;

  return (
    <div className={cx(sharedStyles.bodyContainer, sharedStyles.gutter)}>
      <ContentBlock
        heading={data.title}
        headingZH={data.titleZH}
        cta={{
          name: data.cta
            ? configHome.contentBlockGridAreas?.viewMore?.text || "VIEW ALL"
            : undefined,
          url: data.url,
          icon: configHome.viewMore?.icon,
        }}
        withDivider={configHome.headingDivider}
        isHeadingClickable={configHome.isHeadingClickable}
      >
        <SectionContext.Provider
          value={{
            uniqueName: dataSource.key,
          }}
        >
          <div className={styles.gridContainer}>
            {data.items.map((item, index) => {
              if (index === 0) {
                return (
                  <AnchorBlock
                    key={index}
                    {...item}
                    loading="lazy"
                    placeholder={placeholders.landscape}
                    mobileCrop={configTeaser.anchorBlock?.mobileCrop}
                    CustomHeadingElement="h3"
                  />
                );
              }
              return (
                <LatestArticle
                  key={index}
                  {...item}
                  mode="original"
                  mobileMode={configHome.contentBlockGridAreas?.mobileMode}
                  placeholders={placeholders}
                  displayPublishDate={configTeaser.publishDate}
                />
              );
            })}
          </div>
        </SectionContext.Provider>
      </ContentBlock>
    </div>
  );
}
