import { useState } from "react";
import cx from "classnames";

import { onClickMaybeScrollToHref } from "../../../utils/scroll";
import { Body } from "../../Element/Typography";

import styles from "./index.module.css";
import Arrow from "../../../components/Element/Arrow/Arrow";

type Props = {
  title: React.ReactNode;
  items: {
    label: React.ReactNode;
    href: string;
  }[];
  initiallyExpanded?: boolean;
  prenumbered?: boolean;
};

export default function TableOfContent({
  title,
  items,
  initiallyExpanded = false,
  prenumbered,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);

  return (
    <section className={cx(isExpanded && styles.expanded)}>
      <button
        className={styles.title}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Body>{title}</Body>
        <Arrow size={18} direction="down" />
      </button>
      <ol className={styles.list}>
        {items.map((item, i) => (
          <li key={i} className={styles.listItem}>
            <a
              className={styles.link}
              href={item.href}
              onClick={onClickMaybeScrollToHref}
            >
              {prenumbered ? "" : `${i + 1}. `}
              {item.label}
            </a>
          </li>
        ))}
      </ol>
    </section>
  );
}
