export const FullScreenIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
  >
    <path
      d="M6.05431 1.00684H1.20117V5.85998"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9961 5.85998L18.9961 1.00684L14.143 1.00684"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.143 17.9927L18.9961 17.9927L18.9961 13.1395"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.20117 13.1395L1.20117 17.9927L6.05431 17.9927"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PlayButtonWhite = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65"
    height="65"
    viewBox="0 0 65 65"
    fill="none"
  >
    <circle
      cx="32.0545"
      cy="32.0545"
      r="31.0545"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M46.9092 32.0547L24.6274 44.9192L24.6274 19.1903L46.9092 32.0547Z"
      fill="white"
    />
  </svg>
);

export const ShareIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
  >
    <path
      d="M17.2008 3.85C17.2008 5.42699 15.9948 6.69999 14.5008 6.69999C13.0068 6.69999 11.8008 5.42699 11.8008 3.85C11.8008 2.273 13.0068 1 14.5008 1C15.9948 1 17.2008 2.273 17.2008 3.85Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.4 10.5004C6.4 12.0774 5.194 13.3504 3.7 13.3504C2.206 13.3504 1 12.0774 1 10.5004C1 8.92339 2.206 7.65039 3.7 7.65039C5.194 7.65039 6.4 8.92339 6.4 10.5004Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2008 17.1498C17.2008 18.7268 15.9948 19.9998 14.5008 19.9998C13.0068 19.9998 11.8008 18.7268 11.8008 17.1498C11.8008 15.5728 13.0068 14.2998 14.5008 14.2998C15.9948 14.2998 17.2008 15.5728 17.2008 17.1498Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.0332 11.9316L12.1832 15.7135"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1653 5.28809L6.02734 9.06992"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SearchIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <circle
      cx="10.3334"
      cy="10.6803"
      r="7.92416"
      transform="rotate(8.76892 10.3334 10.6803)"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M15.6475 16.604L21.8247 22.5002"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const SmallSearchIcon = (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 23 23"
  >
    <circle
      cx="9.727"
      cy="9.853"
      r="6.968"
      transform="rotate(8.769 9.727 9.853)"
      stroke="#000"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="m14.472 15.142 5.515 5.265"
      stroke="#000"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const BurgerMenuIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="21"
    viewBox="0 0 26 21"
    fill="none"
  >
    <path
      d="M1.5 1.5H24.5"
      stroke="black"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M1.5 10.731H24.5"
      stroke="black"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M1.5 19.5H24.5"
      stroke="black"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </svg>
);

export const LoginIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="25"
    viewBox="0 0 23 25"
    fill="none"
  >
    <circle
      cx="11.0051"
      cy="6.68431"
      r="4.95238"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M21.0189 23.2676H1.08653C1.51438 16.9723 5.94691 12.2676 11.0527 12.2676C16.1586 12.2676 20.5911 16.9723 21.0189 23.2676Z"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
);

export const CtaIcon = (
  <svg
    width="9"
    height="15"
    viewBox="0 0 9 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 13.668L7.5 7.66797L1.5 1.66797"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const AlertIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 -2 12 12"
    fill="none"
  >
    <path
      d="M10.6348 8.44789L6.98609 2.36539C6.77609 2.01664 6.40859 1.80664 5.99984 1.80664C5.59109 1.80664 5.22359 2.01664 5.01359 2.36539L1.36484 8.44789C1.15109 8.80414 1.14734 9.24664 1.34984 9.60664C1.55234 9.96664 1.93859 10.1916 2.35109 10.1916H9.64859C10.0611 10.1916 10.4473 9.96664 10.6498 9.60664C10.8523 9.24664 10.8486 8.80039 10.6348 8.44789ZM5.62484 4.79539C5.62484 4.58914 5.79359 4.42039 5.99984 4.42039C6.20609 4.42039 6.37484 4.58914 6.37484 4.79539V7.19914C6.37484 7.40539 6.20609 7.57414 5.99984 7.57414C5.79359 7.57414 5.62484 7.40539 5.62484 7.19914V4.79539ZM5.99984 8.77789C5.79359 8.77789 5.62484 8.60914 5.62484 8.40289C5.62484 8.19664 5.79359 8.02789 5.99984 8.02789C6.20609 8.02789 6.37484 8.19664 6.37484 8.40289C6.37484 8.60914 6.20609 8.77789 5.99984 8.77789Z"
      fill="#FF6C61"
    />
  </svg>
);
