import { configHome, configTeaser, DataSource } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  AnchorBlock,
  ContentBlock,
  LatestArticle,
  SectionContext,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { extractHomeContentData, HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  dataSource: DataSource;
  alternateBg?: boolean;
};

/**
 * Description: ContentBlock 3 items
 */
export default function HomeContentBlockK({
  data,
  dataSource,
  alternateBg,
}: Props): ReactNode {
  const contentBlockK = extractHomeContentData(data, dataSource);

  return (
    <div
      className={cx(
        alternateBg && styles.backgroundFill,
        alternateBg && styles.gutterTopBottom,
        styles.contentBlockKContainer,
      )}
    >
      {contentBlockK && (
        <div className={cx(styles.bodyContainer, styles.gutter)}>
          <ContentBlock
            inlineGutter={false}
            heading={contentBlockK.title}
            cta={{
              name: "VIEW ALL",
              url: contentBlockK.url,
              icon: configHome.viewMore?.icon,
            }}
            withDivider={configHome.headingDivider}
            isHeadingClickable={configHome.isHeadingClickable}
          >
            <SectionContext.Provider
              value={{
                uniqueName: configHome.contentBlockK?.skipSectionContext
                  ? undefined
                  : contentBlockK.uniqSectionName,
              }}
            >
              <div className={styles.contentBlockThreeItemsKContainer}>
                {contentBlockK.items.map((item, index) => {
                  return (
                    <div key={index} className={styles.contentBlockThreeItemsK}>
                      {index === 0 ? (
                        <div className={styles.bodyContainerK}>
                          <AnchorBlock
                            {...item}
                            loading="lazy"
                            placeholder={placeholders.landscape}
                            mobileCrop={configTeaser.anchorBlock?.mobileCrop}
                            CustomHeadingElement="h3"
                          />
                        </div>
                      ) : (
                        <div
                          className={cx(
                            styles.mDownGutterSides,
                            styles.latestArticleK,
                          )}
                        >
                          <LatestArticle
                            {...item}
                            mode="landscape"
                            placeholders={placeholders}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </SectionContext.Provider>
          </ContentBlock>
        </div>
      )}
    </div>
  );
}
