/**
 * Company: SPHMedia
 * Description: Implement call to action image button
 */

import placeholders from "@pub/placeholders";
import { Button, ResponsiveImage } from "@sphtech/dmg-design-system";
import { ReactElement } from "react";

import { extractCrop, ResolvedCallToActionImageElement } from "../elements";

type CallToActionImageProps = {
  element: ResolvedCallToActionImageElement;
  variant: "primary" | "secondary";
  bodyMaxWidth: number;
};

export default function CallToActionImage({
  element,
  variant,
  bodyMaxWidth,
}: CallToActionImageProps): ReactElement {
  const image = extractCrop(element.relation, "original");
  return (
    <Button
      variant={variant}
      data-event-label="image"
      href={element.additionalFields.destinationUrl?.value}
      target="_blank"
    >
      {image ? (
        <ResponsiveImage
          {...image}
          displayWidth={bodyMaxWidth}
          errorSrc={placeholders.landscape.src}
          loading="lazy"
        />
      ) : (
        "Go"
      )}
    </Button>
  );
}
