import { useEffect, useState } from "react";

export default function useSticky() {
  const [sticky, setSticky] = useState(false);
  useEffect(() => {
    let offset: number;
    function getOffset() {
      const firstH1Rect = document.querySelector("h1")?.getBoundingClientRect();
      offset = firstH1Rect ? firstH1Rect.bottom + window.scrollY : 300;
      onScroll();
    }
    function onScroll() {
      setSticky(window.scrollY > offset);
    }
    getOffset();

    window.addEventListener("resize", getOffset, { passive: true });
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      window.removeEventListener("resize", getOffset);
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return { sticky };
}
