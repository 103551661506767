// CaaS Image API
// - https://sph.atlassian.net/wiki/spaces/PAPP/pages/1514865469/CaaS+how-tos#Image-optimisation-API
// - https://github.com/SPHTech-Applications/caas-platform-img-optimisation-lambda/blob/main/docs/quick-start.md#how-to-use-the-image-optimisation-api

type caasImageFormats = "jpg" | "png" | "webp" | "avif" | "gif" | "svg";

export const getCaasUrl = (
  baseUrl: string,
  width: number,
  q?: string,
  f?: caasImageFormats,
) =>
  `${baseUrl}?${new URLSearchParams({ w: `${width}`, q: q || "85", ...(f ? { f } : {}) })}`;

const roundForCache = (pixels: number) => Math.ceil(pixels / 10) * 10;

// ToDo: add unit tests
export const getCaasSrcs = (
  baseUrl: string,
  srcWidth: number,
  displayWidth?: number,
) => {
  if (
    // data urls can not be amended with resize parameters, mostly used for testing and storybook
    baseUrl.startsWith("data:") ||
    // can not optimise image without approximate display size
    !displayWidth ||
    // reduce image load failures in VRT
    import.meta.env.VITE_SKIP_SRC_SETS
  ) {
    return {
      src: baseUrl,
    };
  }
  // the default image src points at a 1x image of the given display width
  const defaultWidth = Math.min(roundForCache(displayWidth), srcWidth);
  const has2XRes = defaultWidth * 2 <= srcWidth;
  // widths for the srcset
  const widths = [
    // 0.5x to allow browser to load smaller image if display below the given display width
    roundForCache(defaultWidth * 0.5),
    // 0.8x for smart phones, e.g. desktop full width 1000px * 0.8 = 800px = mobile screen @2x
    roundForCache(defaultWidth * 0.8),
    // also include 1x in srcset
    defaultWidth,
    // add 2x image if source is high res enough
    has2XRes ? roundForCache(defaultWidth * 2) : undefined,
    // add 3x image if source is high res enough
    defaultWidth * 3 <= srcWidth ? roundForCache(defaultWidth * 3) : undefined,
  ];

  return {
    srcSet: widths
      .map(
        (width) => width && [getCaasUrl(baseUrl, width), `${width}w`].join(" "),
      )
      .concat(
        // offer max resolution if not high res enough for 2x
        !has2XRes && srcWidth > displayWidth
          ? `${baseUrl} ${srcWidth}w`
          : undefined,
      )
      .filter(Boolean)
      .join(","),
    src: getCaasUrl(baseUrl, defaultWidth),
  };
};
