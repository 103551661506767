import { ReactNode } from "react";
import * as Typography from "../../Element/Typography";
import styles from "./summaryitem.module.css";

type SummaryItemProps = {
  url: string;
  title: ReactNode;
  beforeTitle?: ReactNode;
  afterTitle?: ReactNode;
  publishedDate?: string;
  dateFormat?: string;
  CustomHeadingElement?: "h1" | "h2" | "h3";
};

type SummaryItemReturnType = ({
  url,
  title,
  beforeTitle,
  publishedDate,
  dateFormat,
}: SummaryItemProps) => ReactNode;

const { DateLine, DateTime, Heading } = Typography;
const { SubHeading, SubHeading3 } = Heading;

/**
 * Used in modules to display a summary of an article.
 * SummaryItemLarge - large variant
 * SummaryItemSmall - small variant
 */
function createSummaryItem(size: "small" | "large"): SummaryItemReturnType {
  const Heading = size === "large" ? SubHeading : SubHeading3;
  const defaultHeadingLevel = size === "large" ? "h2" : "h3";
  return ({
    url,
    title,
    beforeTitle,
    afterTitle,
    publishedDate,
    dateFormat,
    CustomHeadingElement = defaultHeadingLevel,
  }: SummaryItemProps): ReactNode => {
    const dateElement = publishedDate && (
      <DateLine>
        <DateTime date={new Date(publishedDate)} format={dateFormat} />
      </DateLine>
    );

    return (
      <div className={styles.textContainer}>
        {beforeTitle}
        <a href={url} className={`${styles.lineClamp} ${styles.title}`}>
          <Heading CustomElement={CustomHeadingElement}>{title}</Heading>
        </a>
        {afterTitle && (
          <span className={`${styles.description} ${styles.lineClamp}`}>
            {afterTitle}
          </span>
        )}
        {dateElement}
      </div>
    );
  };
}

export const SummaryItemLarge = createSummaryItem("large");
export const SummaryItemSmall = createSummaryItem("small");
