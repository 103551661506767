import React, { CSSProperties, useState } from "react";
import styles from "./index.module.css";
import { getCaasSrcs } from "./caas";

const ResponsiveImage: React.FC<{
  /** the url of the image, should be a CaaS image url to support srcset optimization */
  src: string;
  /** optional error src to be shown if image fails to load */
  errorSrc?: string;
  /** optional low quality image placeholder for mobile to be shown in the background while image loads */
  mobileOnlyLqip?: string;
  /** the width of the image referenced in src  */
  srcWidth: number;
  /** the height of the image referenced in src  */
  srcHeight: number;
  /** fill the parent element with either cover or contain object fit, the image will have 100% height and width */
  fill?: "cover" | "contain";
  /** together with fill we might want to force a specific aspect ratio different from the src image dimension  */
  fillAspectRatio?: number;
  /** when using fill one might want to customize object position, for now we just support center top for portraits, to prevent cut off heads, besides the default center center */
  fillPosition?: "center center" | "center top";
  /** the width the 1x image is supposed to be displayed at, usually the max width that the container can reach, take care beyond 1000 and do not exceed 2000. */
  displayWidth?: number;
  /** the above props source, fill and display props can be switched out for larger screens by providing different values, e.g. a different crop, via the mobileUp prop */
  mobileUp?: {
    src: string;
    srcWidth: number;
    srcHeight: number;
    fill?: "cover" | "contain";
    fillAspectRatio?: number;
    fillPosition?: "center center" | "center top";
    displayWidth?: number;
  };
  /** img alt attribute, requiered for accessibility, can be empty string for decorative images */
  alt: string;
  /** img loading for browser nativ lazy loading */
  loading?: "lazy" | "eager";
}> = ({
  src: srcProp,
  srcWidth,
  srcHeight,
  displayWidth,
  mobileUp,
  alt,
  loading = "lazy",
  fill,
  fillAspectRatio,
  fillPosition,
  errorSrc,
  mobileOnlyLqip,
}) => {
  const srcSets = getCaasSrcs(srcProp, srcWidth, displayWidth);
  const [showErrorSrc, setShowErrorSrc] = useState(false);

  const style: CSSProperties & {
    "--custom-aspect-ratio"?: number;
    "--custom-object-fit"?: string;
    "--custom-object-position"?: string;
    "--custom-height"?: string;
    "--custom-mobile-up-aspect-ratio"?: number;
    "--custom-mobile-up-object-fit"?: string;
    "--custom-mobile-up-object-position"?: string;
    "--custom-mobile-up-height"?: string;
    "--custom-mobile-only-background-image"?: string;
  } = {
    "--custom-aspect-ratio": fillAspectRatio ?? srcWidth / srcHeight,
  };
  if (mobileOnlyLqip) {
    style["--custom-mobile-only-background-image"] = `url(${mobileOnlyLqip})`;
  }
  if (fill) {
    style["--custom-object-fit"] = fill;
    style["--custom-object-position"] = fillPosition;
    style["--custom-height"] = "100%";
  }
  // https://github.com/whatwg/html/pull/8008
  // https://caniuse.com/mdn-html_elements_img_sizes_auto
  // - only supported by Chrome as of Aug 24
  // - but no regression detected for FF and Safari
  const shouldSizesAuto = loading === "lazy";
  if (
    shouldSizesAuto &&
    // skip setting containIntrinsicSize will fill to allow upscaling when filling
    !fill
  ) {
    style.containIntrinsicSize = `${srcWidth}px ${srcHeight}px`;
  }
  const props = {
    className: styles.base,
    alt,
    loading: import.meta.env.VITE_SKIP_LAZY ? undefined : loading,
    sizes: shouldSizesAuto
      ? "auto"
      : [
          displayWidth && displayWidth >= 1000 && `(max-width: 999px) 100vw`,
          displayWidth && `${displayWidth}px`,
        ]
          .filter(Boolean)
          .join(","),
    width: shouldSizesAuto ? srcWidth : undefined,
    height: shouldSizesAuto ? srcHeight : undefined,
    onError: errorSrc
      ? () => {
          setShowErrorSrc(true);
        }
      : undefined,
  };

  if (showErrorSrc) {
    return <img className={props.className} src={errorSrc} alt={props.alt} />;
  }

  if (mobileUp) {
    const mobileUpSrcSets = getCaasSrcs(
      mobileUp.src,
      mobileUp.srcWidth,
      mobileUp.displayWidth || displayWidth,
    );
    if (mobileUp.displayWidth && !shouldSizesAuto) {
      props.sizes = [
        displayWidth && displayWidth >= 1000
          ? `(max-width: 999px) 100vw`
          : `(max-width: 999px) ${displayWidth}px`,
        `${mobileUp.displayWidth}px`,
      ]
        .filter(Boolean)
        .join(",");
    }
    style["--custom-mobile-up-aspect-ratio"] =
      mobileUp.fillAspectRatio ?? mobileUp.srcWidth / mobileUp.srcHeight;

    if (mobileUp.fill) {
      style["--custom-mobile-up-object-fit"] = mobileUp.fill;
      style["--custom-mobile-up-object-position"] = mobileUp.fillPosition;
      style["--custom-mobile-up-height"] = "100%";
    }

    return (
      <picture>
        <source
          srcSet={srcSets.srcSet || srcSets.src}
          media="(max-width: 999px)"
        />
        <source
          srcSet={mobileUpSrcSets.srcSet || mobileUpSrcSets.src}
          media="(min-width: 1000px)"
        />

        <img {...props} {...srcSets} style={style} />
      </picture>
    );
  }

  return <img {...props} {...srcSets} style={style} />;
};

export default ResponsiveImage;
