import { buildUrlWithGARefCode } from "@app/utils/ga/tracking";
import { configTrackingCode } from "@pub/config";
import { ResponsiveImage } from "@sphtech/dmg-design-system";
import { ContentHubData } from "@src/app/types/ServerCustomContext";
import { ReactNode } from "react";

import styles from "../contenthub.module.css";

type ContentHubFeaturedSectionProps = {
  featuredSection: ContentHubData["featuredSection"];
};

export default function ContentHubFeaturedSection({
  featuredSection,
}: ContentHubFeaturedSectionProps): ReactNode {
  return (
    featuredSection &&
    featuredSection.length > 0 && (
      <div className={styles.sectionContainer}>
        {featuredSection.map(({ title, targetUrl, image }, index) => {
          if (!image) {
            return;
          }
          const urlWithRef = buildUrlWithGARefCode(
            targetUrl || "",
            configTrackingCode.contenthub?.featured || "",
          );
          return (
            <div key={index} className={styles.sectionItem}>
              <a href={urlWithRef}>
                <ResponsiveImage
                  alt={image.alt}
                  src={image.src}
                  srcHeight={image.srcHeight}
                  srcWidth={image.srcWidth}
                  displayWidth={320}
                />
              </a>
              <a href={urlWithRef} className={styles.sectionLink}>
                {title}
              </a>
            </div>
          );
        })}
      </div>
    )
  );
}
