import {
  Button,
  Divider,
  ImageCrop,
  ResponsiveImage,
  Typography,
} from "../../../main";
import cx from "classnames";
import { PropsWithChildren, ReactNode } from "react";
import { TextLink } from "../../Element/Typography";
import { CtaIcon } from "../../Theme/assets/icons/commonIcons";
import styles from "./sponsorshipbox.module.css";

const { Heading, Body } = Typography;
const { SubHeading2 } = Heading;

export default function SponsorshipBox({
  title,
  description,
  image,
  ctaUrl,
  ctaText,
  buttonType,
  inPage,
  inMenu,
  showCtaIcon = true,
  hideDivider,
}: PropsWithChildren<{
  title?: ReactNode;
  description?: ReactNode;
  image?: ImageCrop;
  ctaUrl?: string;
  ctaText?: string;
  buttonType?: "primary" | "secondary";
  inPage?: boolean;
  inMenu?: boolean;
  showCtaIcon?: boolean;
  hideDivider?: {
    top?: boolean;
    bottom?: boolean;
  };
}>): ReactNode {
  return (
    <div className={styles.gutter}>
      {!hideDivider?.top && <Divider />}
      <div
        className={cx(
          styles.container,
          inPage && styles.page,
          inMenu && styles.menu,
        )}
      >
        {image && ( // Check if image exists before rendering
          <div className={styles.imageContainer}>
            <ResponsiveImage {...image} displayWidth={220} />
          </div>
        )}
        <div className={cx(styles.content, inMenu && styles.menu)}>
          <SubHeading2>
            {title} {inMenu && description}
          </SubHeading2>
          <div className={cx(styles.description, inMenu && styles.menu)}>
            <Body>{description}</Body>
          </div>

          {/* remove bullet support first */}
          {/* <Body>
                <ul className={styles.bullets}>
                  {bullets?.map((bullet, index) => (
                    <li key={index}>{bullet}</li>
                  ))}
                </ul>
              </Body> */}
        </div>
        <div
          className={cx(
            styles.cta,
            inPage && styles.page,
            inMenu && styles.menu,
          )}
        >
          {ctaUrl &&
            (buttonType === "primary" ? (
              <Button variant={buttonType} href={ctaUrl}>
                {ctaText}
              </Button>
            ) : (
              <TextLink href={ctaUrl}>
                {ctaText}
                {showCtaIcon && CtaIcon}
              </TextLink>
            ))}
        </div>
      </div>
      {!hideDivider?.bottom && <Divider />}
    </div>
  );
}
